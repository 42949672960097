<template>
  <div id="collaborators-modal">
    <div class="font-size-md">
      <div class="mb-5">
        <div class="share-urls" v-if="getProjectInfo.shareUrls">
          <div class="mb-4">
            <div
              class="d-flex flex-row align-items-center mb-2 justify-content-between"
            >
              <p class="m-0">Anyone with this link can view your project</p>
              <b-button
                class="border-0 color-shade-4 p-0 m-0 bg-transparent font-size-sm shadow-none"
                @click="generateNewUrl('read')"
              >
                Reset
              </b-button>
            </div>
            <b-input-group class="w-100 mb-2 pq-input-group">
              <input
                class="w-100"
                type="text"
                ref="shareLinkView"
                :value="`${PIQUASSO_URL}/share/${getProjectInfo.shareUrls.readAccessUrl}`"
                disabled
              />
              <button
                id="copy-to-clipboard-view"
                class="btn fa copy-to-clipboard-btn"
                @click="
                  $utilFunc.copyToClipboard($refs.shareLinkView.value)
                  popoverCopyEdit = false
                  popoverTrigger('popoverCopyView')
                "
              >
                <font-awesome-icon
                  class="fa-clipboard"
                  :icon="['fa', 'clipboard']"
                  size="lg"
                  name="Clipboard"
                />
                <b-popover
                  custom-class="copy-to-clipboard-popover"
                  target="copy-to-clipboard-view"
                  placement="bottom"
                  triggers="manual"
                  :show.sync="popoverCopyView"
                >
                  Copied to clipboard!
                </b-popover>
              </button>
            </b-input-group>
          </div>
          <div>
            <div
              class="d-flex flex-row align-items-center mb-2 justify-content-between"
            >
              <p class="m-0">Anyone with this link can edit your project</p>
              <b-button
                class="border-0 color-shade-4 p-0 m-0 bg-transparent font-size-sm shadow-none"
                @click="generateNewUrl('write')"
              >
                Reset
              </b-button>
            </div>
            <b-input-group class="w-100 mb-2 pq-input-group">
              <input
                class="w-100"
                type="text"
                ref="shareLinkEdit"
                :value="`${PIQUASSO_URL}/share/${getProjectInfo.shareUrls.writeAccessUrl}`"
                disabled
              />
              <button
                id="copy-to-clipboard-edit"
                class="btn fa copy-to-clipboard-btn"
                @click="
                  $utilFunc.copyToClipboard($refs.shareLinkEdit.value)
                  popoverCopyView = false
                  popoverTrigger('popoverCopyEdit')
                "
              >
                <font-awesome-icon
                  class="fa-clipboard"
                  :icon="['fa', 'clipboard']"
                  size="lg"
                  name="Clipboard"
                />
                <b-popover
                  custom-class="copy-to-clipboard-popover"
                  target="copy-to-clipboard-edit"
                  placement="bottom"
                  triggers="manual"
                  :show.sync="popoverCopyEdit"
                >
                  Copied to clipboard!
                </b-popover>
              </button>
            </b-input-group>
          </div>
        </div>
      </div>
      <div>
        <p class="mb-2 font-size-lg font-weight-bold color-shade-2">
          Project Members
        </p>
        <div class="invite-collaborator position-relative">
          <b-input-group
            class="w-100 mb-2 pq-input-group pq-search-input-group"
          >
            <button class="btn fa h-100 search-btn" @click="autoSuggestion">
              <font-awesome-icon
                class="mr-2 search-icon"
                :icon="['fa', 'search']"
                size="sm"
              />
            </button>
            <input
              class="w-100 h-100"
              type="text"
              v-model="searchStr"
              placeholder="Invite people"
            />
          </b-input-group>
          <ul class="p-0 m-0 suggestion-list">
            <li v-for="item in suggestions" :key="item.uuid">
              <button
                class="d-flex align-items-center w-100 collaborator"
                @click="
                  !isCollaborator(item.userName)
                    ? shareProject(item.id, 'read')
                    : null
                "
              >
                <img
                  :src="item.avatar"
                  alt="Profile picture"
                  class="mr-3 profile-img"
                />
                <div class="d-flex flex-column align-items-start">
                  <p>{{ item.firstName }} {{ item.lastName }}</p>
                  <p v-if="isCollaborator(item.userName)" class="font-size-sm">
                    @{{ item.userName }} is already a collaborator in this
                    project
                  </p>
                  <p v-else class="font-size-sm">@{{ item.userName }}</p>
                </div>
              </button>
            </li>
          </ul>
        </div>
        <ul class="p-0 m-0 collaborators-list">
          <li
            v-for="(item, index) in getProjectInfo.collaborators"
            :key="item.uuid"
            class="d-flex align-items-center collaborator justify-content-between"
          >
            <div class="d-flex">
              <img
                :src="item.avatar"
                referrerpolicy="no-referrer"
                alt="Profile picture"
                class="mr-3 profile-img"
              />
              <div class="d-flex flex-column">
                <p>{{ item.firstName }} {{ item.lastName }}</p>
                <p class="font-size-sm">@{{ item.userName }}</p>
              </div>
            </div>
            <div class="user-permission">
              <p v-if="item.ProjectPermission.permissionType === 'Owner'">
                {{ item.ProjectPermission.permissionType }}
              </p>
              <div v-else>
                <button
                  :id="`popover-permissions${index}`"
                  class="permission-btn"
                  :disabled="!hasAdminRights"
                >
                  Can {{ item.ProjectPermission.permissionType }}
                  <font-awesome-icon
                    v-if="hasAdminRights"
                    class="ml-2"
                    :icon="['fa', 'chevron-down']"
                    size="sm"
                  />
                </button>
                <b-popover
                  v-if="hasAdminRights"
                  custom-class="search-field-popover"
                  :target="`popover-permissions${index}`"
                  triggers="hover"
                  placement="bottom"
                >
                  <div class="d-flex flex-column">
                    <button
                      class="change-permission-btn p-2"
                      @click="shareProject(item.id, 'write')"
                    >
                      <div class="d-flex flex-row">
                        <div class="mr-2 is-permission-checked flex-shrink-0">
                          <font-awesome-icon
                            v-if="
                              item.ProjectPermission.permissionType === 'write'
                            "
                            class="mr-2 search-icon"
                            :icon="['fa', 'check']"
                            size="sm"
                          />
                        </div>
                        <div class="text-left">
                          <p class="font-weight-bold">Can write</p>
                          <p class="font-size-sm">
                            This user can add, edit and delete anything in the
                            project.
                          </p>
                        </div>
                      </div>
                    </button>
                    <button
                      class="change-permission-btn p-2"
                      @click="shareProject(item.id, 'read')"
                    >
                      <div class="d-flex flex-row">
                        <div class="mr-2 is-permission-checked flex-shrink-0">
                          <font-awesome-icon
                            v-if="
                              item.ProjectPermission.permissionType === 'read'
                            "
                            class="mr-2 search-icon"
                            :icon="['fa', 'check']"
                            size="sm"
                          />
                        </div>
                        <div class="text-left">
                          <p class="font-weight-bold">Can read</p>
                          <p class="font-size-sm">
                            This user can add, edit and delete anything in the
                            project.
                          </p>
                        </div>
                      </div>
                    </button>
                    <button
                      class="change-permission-btn p-2"
                      @click="setProjectOwner(item.id)"
                    >
                      <div class="d-flex flex-row">
                        <div
                          class="mr-2 is-permission-checked flex-shrink-0"
                        ></div>
                        <div class="text-left">
                          <p class="font-weight-bold">Set as Owner</p>
                          <p></p>
                        </div>
                      </div>
                    </button>
                    <button
                      class="change-permission-btn p-2"
                      @click="removeCollaborator(item.id)"
                    >
                      <div class="d-flex flex-row">
                        <div
                          class="mr-2 is-permission-checked flex-shrink-0"
                        ></div>
                        <div class="text-left">
                          <p class="font-weight-bold">Remove from Project</p>
                          <p></p>
                        </div>
                      </div>
                    </button>
                  </div>
                </b-popover>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  SEARCH_USER,
  ADD_COLLABORATOR,
  SET_PROJECT_OWNER,
  REMOVE_COLLABORATOR,
  GENERATE_NEW_ACCESS_URL,
} from '@/store/actions.type'

export default {
  name: 'CollaboratorsModal',
  data() {
    return {
      projectId: this.$route.params.projectId,
      PIQUASSO_URL: process.env.VUE_APP_PIQUASSO_URL,
      searchStr: '',
      suggestions: [],
      timer: null,
      popoverCopyView: false,
      popoverCopyEdit: false,
    }
  },
  methods: {
    async autoSuggestion() {
      if (this.searchStr.length >= 3) {
        this.suggestions = await this.$store.dispatch(
          SEARCH_USER,
          this.searchStr
        )
      } else {
        this.suggestions = []
      }
    },
    isCollaborator(usr) {
      return this.getProjectInfo.collaborators
        .map((u) => u.userName)
        .includes(usr)
    },
    async shareProject(id, permissionType) {
      // Check if user is a collaborator already
      const payload = {
        UserId: id,
        ProjectId: this.$route.params.projectId,
        permissionType,
      }
      this.searchStr = ''
      await this.$store.dispatch(ADD_COLLABORATOR, payload)
    },
    async setProjectOwner(id) {
      const payload = {
        UserId: id,
        ProjectId: this.$route.params.projectId,
      }
      await this.$store.dispatch(SET_PROJECT_OWNER, payload)
    },
    async removeCollaborator(id) {
      const payload = {
        UserId: id,
        ProjectId: this.$route.params.projectId,
      }
      await this.$store.dispatch(REMOVE_COLLABORATOR, payload)
    },
    async generateNewUrl(accessLevel) {
      const payload = {
        ProjectId: this.$route.params.projectId,
        accessLevel,
      }
      await this.$store.dispatch(GENERATE_NEW_ACCESS_URL, payload)
    },
    popoverTrigger(id) {
      this[id] = true
      setTimeout(() => {
        this[id] = false
      }, 1500)
    },
  },
  watch: {
    searchStr: function () {
      clearTimeout(this.timer)
      this.timer = window.setTimeout(() => {
        this.autoSuggestion()
      }, 500)
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'getProjectById']),
    getProjectInfo() {
      return this.getProjectById(this.projectId)
    },
    hasAdminRights() {
      let hasAdminRights = false
      for (
        let i = 0;
        i < this.getProjectInfo.collaborators.length && !hasAdminRights;
        i++
      ) {
        hasAdminRights =
          this.getProjectInfo.collaborators[i].userName ===
            this.currentUser.userName &&
          this.getProjectInfo.collaborators[i].ProjectPermission
            .permissionType === 'Owner'
      }
      return hasAdminRights
    },
  },
}
</script>

<style>
#collaborators-modal .modal-content {
  color: var(--pq-font-color-shade3);
  background-color: var(--pq-blue-shade2);
}

#collaborators-modal .modal-content footer {
  display: none;
}

#collaborators-modal .modal-content header h5 {
  color: var(--pq-font-color-shade1);
}

#collaborators-modal .modal-dialog {
  width: 35rem;
}

#collaborators-modal .pq-input-group {
  height: 2.25rem;
}

#collaborators-modal .pq-input-group input {
  color: var(--pq-font-color-shade4);
  background-color: var(--pq-blue-shade3);
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
}

#collaborators-modal .pq-input-group input:focus-visible {
  border: none;
  outline: none;
}

#collaborators-modal .pq-input-group input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--pq-font-color-shade4);
}

#collaborators-modal .pq-input-group input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--pq-font-color-shade4);
}

#collaborators-modal .pq-input-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--pq-font-color-shade4);
}

#collaborators-modal .pq-input-group .copy-to-clipboard-btn {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  color: var(--pq-font-color-shade4);
  background-color: var(--pq-blue-shade4);
  border-radius: 0 5px 5px 0;
  padding: 0;
  margin-right: 0rem;
  box-shadow: none !important;
}

.copy-to-clipboard-popover {
  border-radius: 0.75rem;
  background-color: var(--pq-blue-shade4);
  border: none;
}
.copy-to-clipboard-popover .arrow::before {
  border-bottom-color: transparent;
}
.copy-to-clipboard-popover .arrow::after {
  border-bottom-color: var(--pq-blue-shade4);
}
.copy-to-clipboard-popover > .popover-body {
  color: var(--pq-font-color-shade2);
  font-size: 0.85rem;
  border-radius: 0.5rem;
}

#collaborators-modal .pq-search-input-group input {
  padding-left: 2.5rem;
}

#collaborators-modal .pq-search-input-group .search-btn {
  color: var(--pq-font-color-shade4);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: none;
}

@media (min-width: 576px) {
  #collaborators-modal .modal-dialog {
    max-width: 35rem;
  }
}

#collaborators-modal ul {
  list-style-type: none;
}

#collaborators-modal .collaborator {
  color: var(--pq-font-color-shade3);
  border: none;
  border-radius: 5px;
  background-color: transparent;
  padding: 1rem;
}

#collaborators-modal ul.suggestion-list {
  position: absolute;
  background-color: var(--pq-blue-shade1);
  width: 100%;
  top: 2.5rem;
  border-radius: 5px;
}

#collaborators-modal ul.suggestion-list li button:hover {
  color: var(--pq-font-color-shade1);
  background-color: var(--pq-blue-shade3);
  border-radius: 5px;
}

#collaborators-modal .collaborator .profile-img {
  height: 2.5rem;
  border-radius: 2rem;
}

#collaborators-modal .collaborator p {
  margin: 0;
}

/* Permission set */
.is-permission-checked {
  width: 2rem;
}

.permission-btn {
  color: inherit;
  background-color: transparent;
  border: none;
}

.change-permission-btn {
  color: var(--pq-font-color-shade2);
  background-color: var(--pq-blue-shade3);
  border-bottom: 1px solid var(--pq-blue-shade2);
}

.change-permission-btn:hover {
  background-color: var(--pq-blue-shade4);
}

.change-permission-btn p {
  margin: 0;
}

.search-field-popover {
  font-family: inherit;
  border: none;
  background-color: var(--pq-blue-shade3);
  border-radius: 0.5rem;
}

.search-field-popover .arrow::after {
  border-bottom-color: var(--pq-blue-shade3);
}

.search-field-popover .popover-body {
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0;
}
</style>
