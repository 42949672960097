<template>
  <div>
    <div class="dashboard-project-details pt-5">
      <section class="row">
        <div class="col-md-12 projects-breadcrumb">
          <router-link to="/projects" class="mr-4">Projects</router-link>
          <span class="mr-4 projects-breadcrumb-arrow">></span>
          <router-link to="">{{ getProjectInfo.name }}</router-link>
        </div>
      </section>
      <section class="row">
        <div class="col-lg-12 d-flex">
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Name</p>
            </div>
            <div class="project-details-item-value" @click="renameProject">
              {{ getProjectInfo.name }}
            </div>
          </div>
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Owner</p>
            </div>
            <div
              v-if="projectOwner"
              class="project-details-item-value user-panel"
            >
              <img
                class="mr-2"
                :src="projectOwner.avatar"
                alt="Profile picture"
              />
              {{ projectOwner.firstName }}
            </div>
          </div>
          <button class="project-details-item" @click="showCollaboratorsModal">
            <span class="project-details-item-key">
              <p>Collaborators</p>
            </span>
            <span
              class="project-details-item-value user-panel collaborators-panel"
            >
              <template v-for="item in getProjectInfo.collaborators">
                <img
                  :src="item.avatar"
                  referrerpolicy="no-referrer"
                  alt="Profile picture"
                  :key="item.id"
                />
              </template>
            </span>
          </button>
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Updated at</p>
            </div>
            <div class="project-details-item-value">
              {{
                getProjectInfo.updatedAt &&
                $utilFunc.convertTime(getProjectInfo.updatedAt)
              }}
            </div>
          </div>
          <div class="project-details-item">
            <button class="open-project-btn" @click="this.openProject">
              Open Project
            </button>
          </div>
        </div>
      </section>
      <section class="row project-thumbnail mb-0">
        <div class="col-sm-12 col-xl-10 overflow-hidden">
          <div :id="getProjectInfo.id"></div>
        </div>
      </section>
      <section class="projects-table row">
        <div class="col-sm-12 col-xl-10">
          <grid :cols="cols" :rows="jobRows" :pagination="true" :search="true">
          </grid>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModalBus } from '@/common/eventBus'
import { Grid } from 'gridjs-vue'
import { JOBS_BY_PROJECT_ID, LOAD_PROJECT } from '@/store/actions.type'
import CollaboratorsModal from '@/components/modals/collaborators-modal'
import RenameProjectModal from '@/components/modals/rename-project-modal'
import { CircuitViewer } from '@budapest-quantum-computing-group/piquasso-composer'

export default {
  name: 'DashboardProjectDetails',
  components: {
    Grid,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      projectJobs: [],
      projectName: '',
      cols: [
        'Job ID',
        {
          name: 'Status',
          formatter: (cell) => {
            return this.$gridjs.html(`
              ${cell}`)
          },
        },
        {
          name: 'Submitted By',
          formatter: (cell) => {
            return this.$gridjs.html(`
              <img class="grid-td user-profile-picture mr-2"
                v-on:click.native='this.onRowClicked'
                src="${cell.avatar}"
                alt="Profile picture"
                @click="this.onRowClicked"
              />
              ${cell.firstName}`)
          },
        },
        'Created At',
        {
          name: 'Details',
          formatter: (cell) =>
            this.$gridjs.h(
              'button',
              {
                className: 'project-details-btn btn-primary',
                onClick: () => {
                  return this.$router.push({
                    name: 'dashboard-jobDetails',
                    params: { jobId: cell },
                  })
                },
              },
              'Details'
            ),
        },
      ],
    }
  },

  async mounted() {
    await this.loadProjectInfo()
  },

  created() {
    ModalBus.$on('msgBack', () => {
      this.loadProjectInfo()
    })
  },

  methods: {
    async loadProjectInfo() {
      // Make sure the project is loaded
      await this.$store.dispatch(LOAD_PROJECT, this.projectId)
      this.projectJobs = await this.$store.dispatch(
        JOBS_BY_PROJECT_ID,
        this.projectId
      )
      CircuitViewer.fromJson(
        this.getProjectInfo.projectData,
        this.getProjectInfo.id
      )
    },

    openProject() {
      this.$router.push({
        name: 'composer',
        params: { projectId: this.getProjectInfo.id },
      })
    },

    showCollaboratorsModal() {
      ModalBus.$emit('open', {
        component: CollaboratorsModal,
        title: 'Share your circuits with others',
      })
    },

    // Not the best way to implement this, the data gets refetched each time, better to save it in store and update that record
    async renameProject() {
      await ModalBus.$emit('open', {
        component: RenameProjectModal,
        title: 'Rename Project',
        props: {
          projectName: this.getProjectInfo.name,
          ProjectId: this.getProjectInfo.id,
        },
      })
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'getProjectById']),
    getProjectInfo() {
      return this.getProjectById(this.projectId)
    },
    projectOwner() {
      if (this.getProjectInfo.collaborators) {
        return this.getProjectInfo.collaborators.filter(
          (collaborator) =>
            collaborator.ProjectPermission.permissionType === 'Owner'
        )[0]
      } else {
        return undefined
      }
    },
    jobRows() {
      return this.projectJobs.map((obj) => [
        obj.id,
        obj.status,
        this.currentUser,
        this.$utilFunc.convertTime(obj.updatedAt),
        obj.id,
      ])
    },
  },
}
</script>
